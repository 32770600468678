import { Inject, injectable } from 'inversify-props';
import UserStore from '@/modules/user/store/user.store';
import BranchesModel from '@/modules/cars/models/branches.model';
import CarsApiService, { CarsApiServiceS } from '@/modules/cars/cars-api.service';
import CarsFilterApiService, { CarsFilterApiServiceS } from '@/modules/cars/cars-filter-api.service';
import ParityStore from '@/modules/cars/modules/parity/store/parity.store';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import Month from '@/modules/common/types/month.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import { COUNTRIES_ANY, MILEAGE_ANY, PAYMENT_TERMS_ANY, TRANSMISSION_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import { BRAND, BROKER } from '@/modules/cars/constants/data-source-mode.constant';
import type { IRentalCarVendorsPerCountryDiff, IRentalCarVendorsPerCountryDocument } from '@/modules/cars/models/cars-comp-set.model';
import { ITheme } from './models/cars-filters.model';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import LocationAvailabilityStore from './modules/location-availability/store/location-availability.store';
import FleetStore from './modules/fleet/store/fleet.store';
import CarsStore from './store/cars.store';
import CarsFiltersStore from './store/cars-filters.store';
import CarsCompSetStore from './store/cars.comp-set.store';
import restrictCarsFilters from './utils/restrict-cars-filters.util';
import CAR_FUEL_TYPE from './constants/car-fuel-type.constant';
import PRICE_SHOWN from '../rates/constants/price-shown.constant';
import UserService, { UserServiceS } from '../user/user.service';

export const CarsSharedServiceS = Symbol.for('CarsSharedServiceS');

@injectable(CarsSharedServiceS as unknown as string)
export default class CarsSharedService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(CarsApiServiceS) protected carApiService!: CarsApiService;
    @Inject(CarsFilterApiServiceS) private carFilterApiService!: CarsFilterApiService;
    @Inject(DocumentFiltersServiceS) protected documentFiltersService!: DocumentFiltersService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(UserServiceS) private userService!: UserService;

    readonly parityStoreState: ParityStore = this.storeFacade.getState('ParityStore');
    readonly locationAvailabilityStoreState: LocationAvailabilityStore = this.storeFacade.getState('LocationAvailabilityStore');
    readonly fleetStoreState: FleetStore = this.storeFacade.getState('FleetStore');
    readonly carsStoreState: CarsStore = this.storeFacade.getState('CarsStore');
    readonly carsFilterStoreState: CarsFiltersStore = this.storeFacade.getState('CarsFiltersStore');
    readonly userStoreState: UserStore = this.storeFacade.getState('UserStore');
    readonly carsCompSetStore: CarsCompSetStore = this.storeFacade.getState('CarsCompSetStore');

    public tempHideLocations = [
        'Lyft Los Angeles 3',
        'Lyft Los Angeles 4',
        'Lyft Los Angeles 5',
        'Lyft Los Angeles 6',
        'Lyft Los Angeles 11',
        'Lyft Los Angeles 8',
        'Lyft Los Angeles 10',
        'Lyft Los Angeles 9',
        'Lyft Los Angeles 7',
        'Lyft Los Angeles 13',
        'Lyft Los Angeles 12',
        'Lyft Los Angeles 2',
        'Lyft San Francisco 1',
        'Lyft San Francisco 2',
        'Lyft Los Angeles 1',
        'Lyft New York 1',
        'Lyft New York 2',
        'Lyft New York 4',
        'Lyft Atlanta 1',
        'Lyft Chicago 3',
        'Lyft Chicago 2',
        'Lyft Chicago 1',
        'Lyft Atlanta 2',
    ];
    currentTheme!: ITheme;

    get isLoading() {
        return this.carsFilterStoreState.loading.isLoading();
    }

    get isVansEnabled() {
        return this.currentChain?.isVansFeatureEnabled || false;
    }

    savePickUpCityAndCountry(value: string | null, path?: string) {
        this.fleetStoreState.settings.pickUpCityCode = value;
        this.carsStoreState.settings.pickUpCityCode = value;
        this.parityStoreState.settings.pickUpCityCodes = value ? [value] : [];
        this.locationAvailabilityStoreState.settings.pickUpCityCodes = value ? [value] : [];

        if (path) {
            this.saveCountryByCallerService(path);
        }
    }

    updateUnselectedCompetitorsStore(competitors: string[], action: 'select' | 'unselect'): void {
        let currentUnselectedCompetitors = this.getUnselectedCompetitors();
        let shouldUpdate = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const competitor of competitors) {
            const isAlreadyIncluded = currentUnselectedCompetitors.includes(competitor);
            if (action === 'select') {
                if (!isAlreadyIncluded) {
                    shouldUpdate = true;
                    currentUnselectedCompetitors.push(competitor);
                }
            } else if (isAlreadyIncluded) {
                shouldUpdate = true;
                currentUnselectedCompetitors = currentUnselectedCompetitors.filter(competitorName => competitorName !== competitor);
            }
        }
        if (shouldUpdate) {
            localStorage.setItem(`${this.userStoreState.user?.id}_unselected_competitors`, JSON.stringify(currentUnselectedCompetitors));
        }
    }

    clearUnselectedCompetitorsStore(): void {
        localStorage.removeItem(`${this.userStoreState.user?.id}_unselected_competitors`);
    }

    getUnselectedCompetitors(): string[] {
        if (!this.userStoreState.user) {
            return [];
        }
        const values = localStorage.getItem(`${this.userStoreState.user?.id}_unselected_competitors`);
        return values ? JSON.parse(values) : [];
    }

    savePickUpCitiesAndCountry(values: string[] | null, path?: string) {
        if (values) {
            if (values.length) {
                const [firstValue] = values;
                this.fleetStoreState.settings.pickUpCityCode = firstValue;
                this.carsStoreState.settings.pickUpCityCode = firstValue;
            }
            this.parityStoreState.settings.pickUpCityCodes = values;
            this.locationAvailabilityStoreState.settings.pickUpCityCodes = values;

            if (path) {
                this.saveCountryByCallerService(path);
            }
        }
    }

    saveCountryByCallerService(path: string) {
        switch (path) {
            case 'parity': {
                this.saveCountry(this.parityStoreState.settings.country, true);
                break;
            }
            case 'location-availability': {
                this.saveCountry(this.locationAvailabilityStoreState.settings.country, true);
                break;
            }
            case 'cars-rates': {
                this.saveCountry(this.carsStoreState.settings.country);
                break;
            }
            case 'fleet-density': {
                this.saveCountry(this.fleetStoreState.settings.country, true);
                break;
            }
            default: {
                break;
            }
        }
    }

    saveCountry(value: string | null, emptyCluster: boolean = false) {
        this.fleetStoreState.settings.country = value;
        this.carsStoreState.settings.country = value;
        this.parityStoreState.settings.country = value;
        // Location availability doesn't allow ANY option.
        if (value !== COUNTRIES_ANY) {
            this.locationAvailabilityStoreState.settings.country = value;
        }
        if (emptyCluster) {
            this.saveCluster(null);
        }
    }

    saveCluster(value: string | null) {
        this.carsStoreState.settings.cluster = value;
        this.carsStoreState.settings.isAvgPrice = !!value;
    }

    getLocationNameById(locationId: string) {
        return this.carsFilterStoreState.settings.locations
            ? this.carsFilterStoreState.settings.locations.find(loc => loc.locationId === locationId)
            : null;
    }

    isLocationsFilterLoaded() {
        return !!this.carsFilterStoreState.settings.locations;
    }

    savePos(value: string) {
        this.fleetStoreState.settings.pos = value;
        this.carsStoreState.settings.pos = value;
        this.locationAvailabilityStoreState.settings.pos = value;
    }

    get branches() {
        this.helperService.dynamicLoading(this.carsFilterStoreState.branchesLoading, this.loadBranches.bind(this));
        return this.carsFilterStoreState.branches;
    }

    async loadBranches() {
        const branch = await this.carFilterApiService.chainBranches();
        if (!branch) {
            return false;
        }

        const parent = branch;
        const children = branch.childChains || [];
        parent.childChains = [];
        parent.isBroker = true;

        this.carsFilterStoreState.branches = [parent, ...children];

        return true;
    }

    set currentChain(value: BranchesModel | null) {
        this.carsFilterStoreState.chain = value;
        // Also update deprecated CarsFiltersStore.chain
        // noinspection JSDeprecatedSymbols
        this.carsStoreState.settings.chain = value;
        sessionStorage.setItem('currentChainId', value!.chainId!);
    }

    get currentChain(): BranchesModel | null {
        let { chain } = this.carsFilterStoreState;
        if (!chain) {
            let chainId = sessionStorage.getItem('currentChainId');
            if (!chainId) {
                // eslint-disable-next-line prefer-destructuring
                chainId = this.carsFilterStoreState.branches[0].chainId;
                // chainId = this.userService.user!.chainId!;
            }
            chain = this.carsFilterStoreState.branches.find(item => item.chainId === chainId) || this.carsFilterStoreState.branches[0] || null;
            if (chain) {
                chain.isCurrent = true;
                this.currentChain = chain;
                const theme = chain.theme || this.carsFilterStoreState.settings.theme;
                if (theme) {
                    this.currentTheme = theme;
                }
            }
        }
        return chain;
    }

    get currentChainId(): string {
        return this.currentChain!.chainId!;
    }

    get theme() {
        return this.currentTheme;
    }

    set theme(theme: ITheme) {
        this.currentTheme = theme;
    }

    get currentChainCode() {
        return this.filters.chainCode;
    }

    get chainMode() {
        return this.filters.chainMode;
    }

    get isAvailability() {
        return this.filters.isAvailability && this.carsStoreState.fleetAvailabilityReady;
    }

    get isBrokerMode() {
        return this.filters.chainMode === BROKER;
    }

    get isBrandMode() {
        return this.filters.chainMode === BRAND;
    }

    /**
     * Awaited before any cars page load.
     */
    async init() {
        this.carsFilterStoreState.branchesLoading.start();
        await this.loadBranches();
        this.carsFilterStoreState.branchesLoading.finish();
        this.carsFilterStoreState.loading.start();
        await this.loadFilters();
        this.carsFilterStoreState.loading.finish();
    }

    get filters() {
        this.helperService.dynamicLoading(this.carsFilterStoreState.loading, this.loadFilters.bind(this));
        return this.carsFilterStoreState.settings;
    }

    async loadFilters() {
        const chain = this.currentChain;

        let filters = await this.carFilterApiService.getCarFilters(chain);

        if (!filters) {
            return false;
        }

        if (filters.locations) {
            filters.locations = filters.locations.filter(
                item => this.tempHideLocations.findIndex(hideItem => hideItem.toLowerCase() === item.locationName.toLowerCase()) === -1,
            );

            const userInfo = this.userStoreState.user || { countries: [], locations: [] };
            filters = restrictCarsFilters(filters, userInfo);
        }

        this.carsFilterStoreState.settings = { ...filters, fuelTypes: CAR_FUEL_TYPE };

        this.carsFilterStoreState.filtersReady = true;
        return true;
    }

    // User filter values from localStorage

    getUserFilterValuesStorage(): any {
        return JSON.parse(localStorage.getItem('cars-filters') || '{}');
    }

    setUserFilterValuesStorage(data: {}) {
        localStorage.setItem('cars-filters', JSON.stringify(data));
    }

    getUserFilterValues(area: string, key: string = '_'): any | undefined {
        const storage = this.getUserFilterValuesStorage();
        const hash = `${this.currentChainId}.${this.userStoreState.user!.id}`;
        if (storage[hash] && storage[hash][area] && storage[hash][area][key] && Object.keys(storage[hash][area][key]).length) {
            return storage[hash][area][key];
        }
        return undefined;
    }

    saveUserFilterValues(area: string, data: any, key: string = '_') {
        const storage = this.getUserFilterValuesStorage();
        const hash = `${this.currentChainId}.${this.userStoreState.user!.id}`;
        storage[hash] = storage[hash] || {};
        storage[hash][area] = storage[hash][area] || {};
        storage[hash][area][key] = data;
        if (data === false) {
            delete storage[hash][area][key];
        }
        this.setUserFilterValuesStorage(storage);
    }

    // Settings: vendor list
    // todo - move into separate CarsSettingsService

    get vendorsList() {
        this.helperService.dynamicLoading(this.carsCompSetStore.loading, this.loadVendors.bind(this));
        return this.carsCompSetStore.settings.vendorsList;
    }

    async loadVendors() {
        const vendors = await this.carApiService.getVendorsList();
        const brands = this.filters.allowedBrands?.filter(brand => brand !== this.chainName) || [];
        this.carsCompSetStore.settings.vendorsList = { Brand: brands, ...vendors };
        return true;
    }

    updateCompSetSettings(data: IRentalCarVendorsPerCountryDiff) {
        const chain = this.currentChain;
        return this.carApiService.updateCompSetSettings(chain, data);
    }

    removeCompSetSetting(countryName: string, dataSource: string) {
        const chain = this.currentChain;
        return this.carApiService.removeCompSetSetting(chain, countryName, dataSource);
    }

    updateFiltersFromSessionStorageIfExist(data: { [p: string]: any }) {
        if (data) {
            const [dataSource] = data.data_source;
            const carSettings = this.carsStoreState.settings;

            carSettings.cluster = null;
            carSettings.country = 'Any';
            carSettings.isAvgPrice = false;
            carSettings.dataSource = dataSource;
            carSettings.lor = Number(data.lor);
            carSettings.pos = data.pos;
            carSettings.pickUpCityCode = data.location;
            carSettings.carClasses = data.carCategories;
            carSettings.carFuelTypes = data.fuelTypes;
            carSettings.carClassesPreserve = true;
            carSettings.carFuelTypePreserve = true;
            carSettings.priceShown = PRICE_SHOWN.TOTAL;
            carSettings.paymentTerms = PAYMENT_TERMS_ANY;
            carSettings.transmission = TRANSMISSION_ANY;
            carSettings.mileage = MILEAGE_ANY;

            this.documentFiltersService.storeState.settings.year = data.year;
            this.documentFiltersService.storeState.settings.month = (data.month - 1) as Month;
        }
    }

    resetAllowedVendorsPerCountryData(data: IRentalCarVendorsPerCountryDocument) {
        if (data) {
            this.carsFilterStoreState.settings.allowedVendorsPerCountry = data;
        }
    }

    get chainName() {
        return this.currentChain?.chainName;
    }

    get parentChainName() {
        return this.carsFilterStoreState.branches[0].chainName;
    }

    get isChildChain(): boolean {
        return this.currentChain?.chainId !== this.userService.chainId;
    }

    get useParentSippCode(): boolean {
        return this.carsFilterStoreState.chain?.useParentSippCode || false;
    }

    get isChildChainAndUseParentSippCode(): boolean {
        return this.useParentSippCode && this.isChildChain;
    }

    get isReadonly() {
        if (!this.userService.isAdmin) {
            return true;
        }
        return this.userService.isReadonly || this.isChildChainAndUseParentSippCode;
    }

    get readOnlyNoteMessage(): string {
        if (this.isChildChainAndUseParentSippCode) {
            return `Updates can be implemented only on the ${this.parentChainName} view.`;
        }
        return 'You do not have the credential to update this page. please contact your Admin.';
    }

    get carCategoryManagement() {
        if (this.isChildChainAndUseParentSippCode) {
            return false;
        }
        return this.userService.carCategoryManagement;
    }

    get generalSettings() {
        return this.userService.generalSettings;
    }

    get sippMapManagement() {
        if (this.isChildChainAndUseParentSippCode) {
            return false;
        }
        return this.userService.sippMapManagement;
    }

    get userManagement() {
        return this.userService.userManagement;
    }
}
