import { container, Inject, injectable } from 'inversify-props';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import {
    COUNTRIES_ANY, DOORS_ANY, MILEAGE_ANY, PAYMENT_TERMS_ANY, TRANSMISSION_ANY, BROKER_TO_BROKER, BROKER_TO_BRAND,
} from '@/modules/cars/constants/car-filter-types.constant';
import CAR_FUEL_TYPE from '@/modules/cars/constants/car-fuel-type.constant';
import { BROKER, BRAND } from '@/modules/cars/constants/data-source-mode.constant';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import _ from 'lodash';
import ParityStore from '@/modules/cars/modules/parity/store/parity.store';
import filterLorsByAllowance from '@/modules/cars/utils/filter-lors-by-allowance.util';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import CarsStore from './store/cars.store';
import CarsService, { CarsServiceS } from './cars.service';
import ICarFilterOption from './interfaces/car-filter-option.interface';
import DocumentFiltersService, { DocumentFiltersServiceS } from '../document-filters/document-filters.service';
import CarsSharedService, { CarsSharedServiceS } from './cars-shared.service';

// todo - rename to CarsRatesFiltersService
export const CarsFiltersServiceS = Symbol.for('CarsFiltersServiceS');

@injectable(CarsFiltersServiceS as unknown as string)
export default class CarsFiltersService {
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;

    readonly storeState: CarsStore = this.storeFacade.getState('CarsStore');
    readonly parityStoreState: ParityStore = this.storeFacade.getState('ParityStore');
    readonly documentFiltersService = container.get<DocumentFiltersService>(DocumentFiltersServiceS);

    private defaultFilterValues: { [key: string]: any } = {};
    private userFilterStorageKey = 'rates';

    constructor() {
        // todo, is this service can be actually created not in MI app?
        if (!this.userService.isCarUser) {
            return;
        }

        let finishUpdateOfDefaultFilters: any = false;

        const settings = this.carsSharedService.getUserFilterValues(this.userFilterStorageKey);
        this.initFilters(settings)
            .then(() => {
                // Remember original chain defaults on first load.
                this.defaultFilterValues = JSON.parse(JSON.stringify(this.extractUserFilterValues()));

                if (settings) {
                    // Setup filters which affect document load
                    Object.assign(this.storeState.settings, {
                        // These filters are stored in defaultFilterValues and can trigger document reload
                        isInit: true,
                        country: settings.country,
                        cluster: settings.cluster,
                        pickUpCityCode: settings.pickUpCityCode,
                        isAvgPrice: settings.isAvgPrice,
                        currentDataSourceMode: settings.currentDataSourceMode,
                        currentBrandAsBroker: settings.currentBrandAsBroker,
                        brokersCompetitors: settings.brokersCompetitors,
                        dataSource: settings.dataSource,
                        // use default values for initial data load
                        competitors: settings.competitors,
                        // use default values for initial data load
                        carClasses: settings.carClasses,
                        pos: settings.pos,
                        lor: settings.lor,
                        vehicleType: settings.vehicleType,
                    });

                    // Remember filters to update after document load
                    finishUpdateOfDefaultFilters = {
                        competitors: settings.competitors,
                        carClasses: settings.carClasses,
                        carFuelTypes: settings.carFuelTypes,
                        transmission: settings.transmission,
                        mileage: settings.mileage,
                        paymentTerms: settings.paymentTerms,
                    };
                }

                // Reset specific filters on pickUpCityCode change
                this.storeFacade.watch(() => this.storeState.settings.pickUpCityCode, async () => {
                    this.refreshDoorsFilters();
                    this.refreshTransmissionFilters();
                    this.refreshMileageFilters();
                    this.refreshPaymentTermsFilters();
                });

                // Update filters on document load
                this.storeFacade.watch(() => this.storeState.document, async () => {
                    // Document can be NULL if we reset filters.
                    if (this.storeState.document) {
                        this.refreshFilters();

                        // Finish update of stored defaults. Update is executed only one time on first document load.
                        if (finishUpdateOfDefaultFilters) {
                            this.storeState.settings.carClassesPreserve = true;
                            this.storeState.settings.carFuelTypePreserve = true;
                            Object.assign(this.storeState.settings, finishUpdateOfDefaultFilters);
                            finishUpdateOfDefaultFilters = false;
                        }
                    }
                });

                // Mark filters as ready
                this.storeState.filtersReady = true;
            });
    }

    async initFilters(settings?: any) {
        await this.initCountries();
        await this.initPickUpCity();
        await this.initDataSource();
        await this.initPos(settings?.pos);
        await this.initLor();
    }

    /**
     * After document load. These filters don't trigger document reload.
     */
    refreshFilters() {
        const { dataSource, isAvgPrice } = this.storeState.settings;
        if (!isAvgPrice) {
            if (dataSource) {
                const data = this.storeState.settings.dataSourceCompetitors[dataSource];
                this.storeState.settings.competitors = data || this.competitorsFilter;
            }

            if (!this.storeState.settings.carClassesPreserve) {
                this.initCarClasses();
            }

            if (!this.storeState.settings.carFuelTypePreserve) {
                this.initCarFuelTypes();
            }
        }

        this.initCompetitorFilters();
        this.initDoorsFilter();
        this.initTransmissionFilter();
        this.initMileageFilter();
        this.initPaymentTermsFilter();
        this.refreshDataSources();
    }

    /**
     * @deprecated Use CarsSharedService.filters
     */
    get settings() {
        return this.carsSharedService.filters;
    }

    get useParentSippCode(): boolean {
        return this.carsService.carFiltersStoreState.chain?.useParentSippCode || false;
    }

    /**
     * @deprecated Use CarsSharedService.branches
     */
    get branches() {
        return this.carsSharedService.branches;
    }

    get competitors() {
        const { currentDocumentCompetitors } = this.storeState.settings;
        const unselectedCompetitors = this.carsSharedService.getUnselectedCompetitors();
        if (currentDocumentCompetitors === null || unselectedCompetitors === null) {
            return this.storeState.settings.competitors;
        }
        return currentDocumentCompetitors.filter(item => !unselectedCompetitors.includes(item));
    }

    async initCountries() {
        if (this.storeState.settings.country) {
            return;
        }
        this.storeState.settings.country = COUNTRIES_ANY;
    }

    async initPickUpCity() {
        if (this.storeState.settings.pickUpCityCode) {
            return;
        }

        const { isVans } = this.storeState.settings;
        const { availability, availabilityVans } = this.carsSharedService.filters;

        if (availability?.length || availabilityVans?.length) {
            const [defaultLocation] = isVans ? availabilityVans! : availability!;
            this.storeState.settings.pickUpCityCode = defaultLocation.id;
        }
    }

    async initDataSource() {
        if (this.storeState.settings.dataSource) {
            return;
        }

        const { dataSources } = this.carsSharedService.filters;
        const { chainName } = this.userService;

        if (dataSources && dataSources.length) {
            const [defaultDataSource] = dataSources;
            this.storeState.settings.dataSourceCompetitors[defaultDataSource] = [];
            this.storeState.settings.dataSource = defaultDataSource;
        }
        if (this.availableDataSources) {
            this.storeState.settings.brokersCompetitors = this.availableDataSources.filter(item => ![chainName, 'Brand'].includes(item));
            this.parityStoreState.settings.dataSources = this.availableDataSources.filter(item => ![chainName].includes(item));
        }
    }

    async initPos(savedPos?: string) {
        if (savedPos) {
            this.storeState.settings.pos = savedPos;
            return;
        }

        if (this.storeState.settings.pos) {
            return;
        }
        const { pos, defaultLocationsPOS } = this.carsSharedService.filters;
        const { pickUpCityCode } = this.storeState.settings;

        if (pos && pos.length) {
            const locationDefaultPOS = pickUpCityCode ? defaultLocationsPOS[pickUpCityCode] : null;
            this.carsSharedService.savePos(locationDefaultPOS || pos[0]);
        }
    }

    async initLor() {
        if (this.storeState.settings.lor) {
            return;
        }
        let { lor } = this.carsSharedService.filters;
        if (this.userService && this.userService.user && this.userService.user.chainName === 'Rentalcars' && lor) {
            const lorsToInclude = [3, 7, 10, 14];
            lor = lor.filter(item => lorsToInclude.includes(item));
        }
        if (lor && lor.length) {
            const [defaultLor] = lor;
            this.storeState.settings.lor = defaultLor;
        }
    }

    filterPickUpCitiesByCountry(country: string) {
        const {
            countries, routes, availability, availabilityVans,
            vanLocations, carLocations,
        } = this.carsSharedService.filters;

        const { isVans } = this.storeState.settings;

        const availabilities = isVans ? availabilityVans : availability;

        const locations = isVans ? vanLocations : carLocations;

        if (!locations || !routes || !availabilities) {
            return [];
        }

        const availableLocations = locations.filter(location => {
            const isAvailableRoute = routes.find(route => route.pickUpLocationId === location.locationId);
            const isAvailableGroup = availabilities.find(group => group.id === location.locationId);

            return isAvailableGroup && isAvailableRoute;
        });

        if (!country || country === COUNTRIES_ANY || !countries) {
            return availableLocations;
        }

        const selectedCountryCodes = countries[country];

        return availableLocations.filter(location => selectedCountryCodes.find(code => code === location.locationId));
    }

    refreshDataSources() {
        const { chainName } = this.userService;
        if (chainName && this.availableDataSources && this.carsService.dataSourcesFromDoc && this.carsService.dataSourcesFromDoc.length > 1) {
            const providers = this.availableDataSources.filter(item => ![chainName, 'Brand'].includes(item));
            this.storeState.settings.brokersCompetitors = _.intersection(
                providers,
                this.carsService.dataSourcesFromDoc,
                this.storeState.settings.brokersCompetitors,
            );
        }
    }

    get availableDataSources() {
        const { pickUpCityCode } = this.storeState.settings;
        const { availability, chainMode, dataSources, availabilityVans } = this.carsSharedService.filters;
        const { dataSourcesFromDoc } = this.carsService;

        const { isVans } = this.storeState.settings;
        const availabilities = isVans ? availabilityVans : availability;

        if (!pickUpCityCode || !availabilities) {
            return null;
        }

        const currentLocation = availabilities.find(location => location.id === pickUpCityCode);

        if (!currentLocation) {
            return [];
        }

        const availableDataSources = Object.keys(currentLocation.path).filter(item => (dataSources || []).includes(item));

        if (chainMode !== BROKER || !dataSourcesFromDoc.length) {
            return availableDataSources;
        }

        return _.intersection(availableDataSources, dataSourcesFromDoc);
    }

    get availableLors() {
        const { pickUpCityCode, pos } = this.storeState.settings;
        const { dataSource, currentDataSourceMode } = this.storeState.settings;
        const { isVans } = this.storeState.settings;
        return this.getAvailableLors(pickUpCityCode, pos, dataSource, currentDataSourceMode, isVans);
    }

    getAvailableLors(
        pickUpCityCode: string | null,
        pos: string | null,
        dataSource: string | null,
        currentDataSourceMode: string,
        isVans: boolean,
    ) {
        const { availability, allowedLORs, availabilityVans } = this.carsSharedService.filters;
        const { currentCompany } = this.userService;

        const availabilities = isVans ? availabilityVans : availability;

        if (!availabilities || !dataSource || !pos || pos === 'N/A' || !currentCompany) {
            return [];
        }

        const currentLocation = availabilities.find(location => location.id === pickUpCityCode);

        if (!currentLocation) {
            return [];
        }

        const { brokersCompetitors } = this.storeState.settings;

        let brokers: string[];
        if (this.isBrokerMode && currentDataSourceMode === BROKER_TO_BROKER && brokersCompetitors) {
            brokers = _.intersection(brokersCompetitors, Object.keys(currentLocation.path));
        } else if (this.isBrokerMode && currentDataSourceMode === BROKER_TO_BRAND) {
            brokers = _.intersection(Object.keys(currentLocation.path), [currentCompany, 'Brand']);
        } else {
            brokers = _.intersection(Object.keys(currentLocation.path), [dataSource]);
        }

        const lors = brokers.flatMap(broker => Object.keys(currentLocation.path[broker][pos] || {}));

        const loksByAvailability = _.uniq(lors);

        return filterLorsByAllowance({ pos, allowedLORs, lorsToFilter: loksByAvailability });
    }

    get availablePos() {
        const carSettings = this.storeState.settings;
        const { brokersCompetitors } = this.storeState.settings;
        const { availability, availabilityVans } = this.carsSharedService.filters;
        const { currentDataSourceMode } = this.storeState.settings;
        const { currentCompany } = this.userService;
        const dataSourcesByDOC: string[] = _.get(this.storeState, 'document.dataSource', []);

        const { isVans } = this.storeState.settings;

        const availabilities = isVans ? availabilityVans : availability;

        if (!availabilities || !currentCompany) {
            return null;
        }

        const { clusters } = this.carsSharedService.filters;

        const { clusterMode } = this.carsSharedService.filters.features;

        const {
            country, cluster, isAvgPrice, pickUpCityCode,
        } = this.storeState.settings;

        if (!country) {
            return [];
        }

        let pickUpCityCodes: string[] = [];
        if (cluster && country && clusters && clusterMode) {
            pickUpCityCodes = clusters[country][cluster];
        } else if (carSettings.pickUpCityCode) {
            pickUpCityCodes.push(carSettings.pickUpCityCode);
        }

        const currentDoc = availabilities.filter(doc => pickUpCityCodes.includes(doc.id));

        if (!currentDoc || !carSettings.dataSource || !dataSourcesByDOC) {
            return null;
        }
        let locationPos: string[] = [];
        const locationBYPOS: { [key: string]: string[] } = {};
        currentDoc.forEach(doc => {
            if (carSettings && carSettings.dataSource) {
                let brokers;
                if (this.isBrokerMode && currentDataSourceMode === BROKER_TO_BROKER && brokersCompetitors) {
                    const competitors = (dataSourcesByDOC || []).length ? dataSourcesByDOC : brokersCompetitors;
                    brokers = _.intersection(competitors, Object.keys(doc.path));
                } else if (this.isBrokerMode && currentDataSourceMode === BROKER_TO_BRAND) {
                    brokers = [currentCompany, 'Brand'];
                } else {
                    brokers = [carSettings.dataSource];
                }

                brokers.forEach(brokerName => {
                    Object.keys(doc.path[brokerName] || {}).forEach(pos => {
                        locationPos.push(pos);

                        if (!(pos in locationBYPOS)) {
                            locationBYPOS[pos] = [];
                        }

                        locationBYPOS[pos].push(doc.id);
                    });
                });
            }
        });

        locationPos = _.uniq(locationPos);
        let availablePos: string[] = [];
        const allLocation = this.filterPickUpCitiesByCountry(country);

        if (cluster && country && clusters && clusterMode) {
            const locationsIds = clusters[country][cluster];
            const filteredLocations = allLocation.filter(location => locationsIds.includes(location.locationId));
            let clusterLocations: string[] = [];
            if (isAvgPrice) {
                clusterLocations = filteredLocations.map(location => location.locationId);
            } else {
                if (!pickUpCityCode) {
                    return null;
                }

                const currentLocation = allLocation.find(location => location.locationId === pickUpCityCode);

                if (!currentLocation) {
                    return null;
                }
                clusterLocations.push(currentLocation.locationId);
            }

            locationPos.forEach(pos => {
                if (carSettings.dataSource) {
                    const availableLocation = Object.values(locationBYPOS[pos]);
                    const diffResult = _.difference(clusterLocations, availableLocation);
                    if (diffResult.length === 0) {
                        availablePos.push(pos);
                    }
                }
            });
        } else {
            availablePos = locationPos;
        }

        if (!availablePos || availablePos.length === 0) {
            this.storeState.settings.pos = 'N/A';
            return null;
        }
        return availablePos;
    }

    get countriesFilter(): string[] {
        const { countries } = this.carsSharedService.filters;

        if (!countries) {
            return [COUNTRIES_ANY];
        }

        const countryNames = new Set([COUNTRIES_ANY].concat(Object.keys(countries)));

        return Array.from(countryNames);
    }

    resetCompetitorsFilter() {
        const mainDocuments = this.storeState.document;
        if (mainDocuments && mainDocuments.checkinDates) {
            Object.keys(mainDocuments.checkinDates || {}).forEach(day => {
                Object.keys(mainDocuments.checkinDates[Number(day)] || {}).forEach(company => {
                    if (this.documentFiltersService.settings.documentVendors) {
                        this.documentFiltersService.settings.documentVendors.add(company);
                    }
                });
            });
        }
    }

    get competitorsFilter(): string[] {
        const { currentCompany } = this.userService;
        const { allowedBrands } = this.carsSharedService.filters;
        if (this.storeState.analysis.documents) {
            this.resetCompetitorsFilter();
        }

        let competitors = [];

        if (this.storeState.settings.dataSource === 'Brand') {
            const { documentVendors } = this.documentFiltersService.settings;
            if (allowedBrands === null || !documentVendors) {
                return [];
            }
            competitors = allowedBrands.filter((brand: string) => brand !== currentCompany)
                .filter(item => documentVendors.has(item))
                .filter(item => this.isProviderAvailableInCurrentPOS(item));
        } else {
            competitors = this.companyCompetitors.filter(item => this.isProviderAvailableInCurrentPOS(item));
        }
        return this.allowedVendorsPerCountryAndDataSource.length && !this.carsService.storeState.settings.isVans
            ? competitors.filter(item => this.allowedVendorsPerCountryAndDataSource.includes(item)) : competitors;
    }

    get companyCompetitors() {
        const { currentCompany } = this.userService;
        const filter = new Set();
        const { document } = this.storeState;

        if (!document || currentCompany === null) {
            return Array.from(filter) as string[];
        }

        const { checkinDates } = document;

        Object.values(checkinDates).forEach(companies => {
            if (!companies) {
                return;
            }
            Object.keys(companies).forEach(companyName => {
                filter.add(companyName);
            });
        });
        filter.delete(currentCompany);

        return Array.from(filter) as string[];
    }

    initCompetitorFilters() {
        const { dataSource } = this.storeState.settings;
        this.storeState.settings.competitors = this.competitorsFilter.length ? this.competitorsFilter : null;

        if (dataSource) {
            this.storeState.settings.dataSourceCompetitors[dataSource] = this.storeState.settings.competitors;
        }
    }

    refreshDoorsFilters() {
        const defaultOption = this.doorsFilter.find(option => !option.disabled);
        this.storeState.settings.doors = defaultOption!.value;
    }

    refreshTransmissionFilters() {
        const defaultOption = this.transmissionFilter.find(option => !option.disabled);
        this.storeState.settings.transmission = defaultOption!.value;
    }

    refreshMileageFilters() {
        const defaultOption = this.mileageFilter.find(option => !option.disabled);
        this.storeState.settings.mileage = defaultOption!.value;
    }

    refreshPaymentTermsFilters() {
        const defaultOption = this.paymentTerms.find(option => !option.disabled);
        this.storeState.settings.paymentTerms = defaultOption!.value;
    }

    get allCarClasses(): string[] {
        return this.carsSharedService.filters.carClass ? this.carsSharedService.filters.carClass : [];
    }

    get allCompetitors(): string[] {
        return this.competitorsFilter ? this.competitorsFilter : [];
    }

    get carClassFilter(): string[] {
        const filter = new Set();
        const { currentCompany } = this.userService;
        const { document } = this.storeState;

        if (!document || currentCompany === null) {
            return Array.from(filter) as string[];
        }

        const { checkinDates } = document;

        Object.values(checkinDates).forEach(companies => {
            if (!companies) {
                return;
            }
            Object.values(companies).forEach(carClasses => {
                Object.keys(carClasses).forEach(carClass => {
                    filter.add(carClass);
                });
            });
        });

        return Array.from(filter) as string[];
    }

    get allowedBrands(): string[] {
        return [...(this.carsSharedService.filters.allowedBrands || [])];
    }

    get brandsFilter(): string[] {
        return [...(this.carsSharedService.filters.brands || [])];
    }

    get allowedVendorsPerCountryAndDataSource() {
        const { dataSource, pickUpCityCode } = this.carsService.storeState.settings;
        if (!pickUpCityCode || this.isBrokerMode) {
            return [];
        }
        const country = this.getCountryByPickUpLocationId(pickUpCityCode);
        if (dataSource && country && this.allowedVendorsPerCountry[country]) {
            return this.allowedVendorsPerCountry[country][dataSource] || [];
        }
        return [];
    }

    getAllowedVendorsPerPosAndDataSource(dataSource: any, pos: any) {
        if (!pos || this.isBrokerMode) {
            return [];
        }
        const country = this.getCountryByPOS(pos);
        if (dataSource && country && this.allowedVendorsPerCountry[country]) {
            return this.allowedVendorsPerCountry[country][dataSource] || [];
        }
        return [];
    }

    initCarClasses() {
        // Disable commercial car class at first load. Temporary solution
        const { isVans } = this.storeState.settings;

        const carClasses = isVans ? this.carClassFilter : this.carClassFilter.filter(carClass => carClass !== 'Commercial');

        this.storeState.settings.carClasses = carClasses;
    }

    initCarFuelTypes() {
        this.storeState.settings.carFuelTypes = [...CAR_FUEL_TYPE];
    }

    getLocationCodeByName(name: string) {
        const { locations } = this.carsSharedService.filters;

        if (!locations || !locations.length) {
            return null;
        }

        const defaultLocation = locations.find(loc => loc.locationName === name);
        return defaultLocation ? defaultLocation.locationId : null;
    }

    getLocationNameByCode(code: string) {
        const { locations } = this.carsSharedService.filters;

        if (!locations || !locations.length) {
            return null;
        }

        const defaultLocation = locations.find(loc => loc.locationId === code);
        return defaultLocation ? defaultLocation.locationName : null;
    }

    get paymentTerms(): ICarFilterOption[] {
        return this.extractCarFilters('paymentTerms', PAYMENT_TERMS_ANY);
    }

    initPaymentTermsFilter() {
        const currentValue = this.storeState.settings.paymentTerms;

        if (!this.paymentTerms.find(option => option.value === currentValue && !option.disabled)) {
            const defaultOption = this.paymentTerms.find(option => !option.disabled);
            this.storeState.settings.paymentTerms = defaultOption ? defaultOption.value : PAYMENT_TERMS_ANY;
        }
    }

    get doorsFilter(): ICarFilterOption[] {
        return this.extractCarFilters('doors', DOORS_ANY);
    }

    initDoorsFilter() {
        const currentValue = this.storeState.settings.doors;

        if (!this.doorsFilter.find(option => option.value === currentValue && !option.disabled)) {
            const defaultOption = this.doorsFilter.find(option => !option.disabled);
            this.storeState.settings.doors = defaultOption ? defaultOption.value : DOORS_ANY;
        }
    }

    get transmissionFilter(): ICarFilterOption[] {
        return this.extractCarFilters('transmission', TRANSMISSION_ANY);
    }

    initTransmissionFilter() {
        const currentValue = this.storeState.settings.transmission;

        if (!this.transmissionFilter.find(option => option.value === currentValue && !option.disabled)) {
            const defaultOption = this.transmissionFilter.find(option => !option.disabled);
            this.storeState.settings.transmission = defaultOption ? defaultOption.value : TRANSMISSION_ANY;
        }
    }

    get mileageFilter(): ICarFilterOption[] {
        return this.extractCarFilters('mileage', MILEAGE_ANY);
    }

    initMileageFilter() {
        const currentValue = this.storeState.settings.mileage;

        if (!this.mileageFilter.find(filterValue => filterValue.value === currentValue)) {
            const defaultOption = this.mileageFilter.find(option => !option.disabled);
            this.storeState.settings.mileage = defaultOption ? defaultOption.value : MILEAGE_ANY;
        }
    }

    getHiddenProvidersInPOS(pos: string): string[] {
        return this.carsSharedService.filters.posProviderHidden[pos] || [];
    }

    isProviderAvailableInCurrentPOS(provider: string, overwritePos: string = ''): boolean {
        const { pos } = this.storeState.settings;
        const usePos = overwritePos.length ? overwritePos : pos || '';
        const hiddenProviders = usePos ? this.getHiddenProvidersInPOS(usePos) : [];
        return !hiddenProviders.find(item => item.toLowerCase() === provider.toLowerCase());
    }

    extractCarFilters(filterFieldName: 'mileage' | 'transmission' | 'doors' | 'paymentTerms', any: string | null): ICarFilterOption[] {
        const filters: ICarFilterOption[] = [];
        const { currentCompany } = this.userService;
        const { document } = this.storeState;

        if (any !== null) {
            filters.push({ value: any, disabled: false });
        }

        const currentFilterValues = this.carsSharedService.filters[filterFieldName];

        if (currentFilterValues) {
            currentFilterValues.forEach(item => filters.push({ value: item, disabled: true }));
        }

        if (!document || currentCompany === null) {
            return filters;
        }

        const { checkinDates } = document;

        Object.values(checkinDates).forEach(companies => {
            if (!companies) {
                return;
            }
            Object.values(companies).forEach(carClasses => {
                Object.values(carClasses).forEach(carClass => {
                    carClass.forEach(carItem => {
                        const currentFilterValue = carItem[filterFieldName];
                        const valueInFiltersIndex = filters.findIndex(filter => filter.value === currentFilterValue);

                        if (currentFilterValue && (valueInFiltersIndex === -1 || filters[valueInFiltersIndex].disabled)) {
                            if (valueInFiltersIndex === -1) {
                                filters.push({ value: currentFilterValue, disabled: false });
                            } else {
                                filters[valueInFiltersIndex].disabled = false;
                            }
                        }
                    });
                });
            });
        });

        return filters;
    }

    get countriesForHolidays() {
        const { country, pos } = this.carsService.storeState.settings;
        if (country !== COUNTRIES_ANY) {
            const selectedCountryCode = this.countryCodes?.find(item => item.name?.toLowerCase() === country?.toLowerCase())?.code;
            if (selectedCountryCode) {
                return _.uniq([pos, selectedCountryCode]);
            }
        }
        return [pos];
    }

    get allChildren() {
        const { branches } = this.carsSharedService;
        const { currentChain } = this.carsSharedService;
        return branches.filter(item => currentChain && item.chainId !== currentChain.chainId);
    }

    get clusters() {
        return this.carsSharedService.filters.clusters;
    }

    get clusterMode() {
        return this.carsSharedService.filters.features.clusterMode;
    }

    /**
     * @deprecated Use carsSharedService.currentChainCode
     */
    get currentChainCode() {
        return this.carsSharedService.filters.chainCode;
    }

    /**
     * @deprecated Use carsSharedService.chainMode
     */
    get chainMode() {
        return this.carsSharedService.filters.chainMode;
    }

    /**
     * @deprecated Use carsSharedService.isAvailability
     */
    get isAvailability() {
        return this.carsSharedService.filters.isAvailability;
    }

    get countryCodes() {
        return this.carsSharedService.filters.countryCodes;
    }

    /**
     * @deprecated Use carsSharedService.isBrokerMode
     */
    get isBrokerMode() {
        const { chainMode } = this.carsSharedService.filters;
        return chainMode === BROKER;
    }

    /**
     * @deprecated Use carsSharedService.isBrandMode
     */
    get isBrandMode() {
        const { chainMode } = this.carsSharedService.filters;
        return chainMode === BRAND;
    }

    get carProvidersDisplayNamesMap() {
        const { carProviders } = this.carsSharedService.filters;
        return _.reduce(
            carProviders,
            (acc, { groupedDocumentName, displayName }) => ({
                ...acc,
                [groupedDocumentName]: displayName,
            }),
            {},
        );
    }

    get selectedLocationName() {
        const { locations } = this.carsSharedService.filters;
        const { pickUpCityCode } = this.storeState.settings;
        const current = (locations || []).find(location => location.locationId === pickUpCityCode);
        return current ? current.locationName : '';
    }

    saveUserFilterValues(key: string = '_') {
        this.carsSharedService.saveUserFilterValues(this.userFilterStorageKey, this.extractUserFilterValues(), key);
    }

    clearUserFilterValues(key: string = '_') {
        this.carsSharedService.saveUserFilterValues(this.userFilterStorageKey, false, key);
        this.carsSharedService.clearUnselectedCompetitorsStore();

        this.fleetFiltersService.storeState.settings.competitors = this.fleetFiltersService.competitorsFilter;

        if (!this.defaultFilterValues) {
            document.location.reload();
        }

        // Prevent document load.
        this.storeState.filtersReady = false;

        // Empty document to not update filters from current document.
        this.storeState.document = null;
        this.storeState.fleetAvailability = null;

        const settings = this.defaultFilterValues;

        Object.assign(this.storeState.settings, {
            isAvgPrice: settings.isAvgPrice,
            country: settings.country,
            cluster: settings.cluster,
            pickUpCityCode: settings.pickUpCityCode,
            currentDataSourceMode: settings.currentDataSourceMode,
            currentBrandAsBroker: settings.currentBrandAsBroker,
            brokersCompetitors: settings.brokersCompetitors,
            dataSource: settings.dataSource,
            competitors: settings.competitors,
            pos: settings.pos,
            lor: settings.lor,
            carClasses: [],
            vehicleType: settings.vehicleType,
            carFuelTypes: [],
            transmission: null,
            mileage: null,
            paymentTerms: null,
        });
        // Also comparison filter
        this.storeState.analysis.settings.comparisonFilter.key = settings.comparisonType;
        this.storeState.analysis.settings.comparisonFilter.values.value = settings.comparisonValue;

        // Trigger refresh because document can not be loaded if triggering filters were not changed.
        this.storeState.settings.carClassesPreserve = false;
        this.storeState.settings.carFuelTypePreserve = false;
        this.refreshFilters();

        // Mark filters as ready and trigger data load
        this.storeState.filtersReady = true;
    }

    extractUserFilterValues() {
        const { settings, analysis } = this.storeState;
        return {
            isAvgPrice: settings.isAvgPrice,
            country: settings.country,
            cluster: settings.cluster,
            pickUpCityCode: settings.pickUpCityCode,
            currentDataSourceMode: settings.currentDataSourceMode,
            currentBrandAsBroker: settings.currentBrandAsBroker,
            brokersCompetitors: settings.brokersCompetitors,
            dataSource: settings.dataSource,
            competitors: settings.competitors,
            pos: settings.pos,
            lor: settings.lor,
            carClasses: settings.carClasses,
            vehicleType: settings.vehicleType,
            carFuelTypes: settings.carFuelTypes,
            transmission: settings.transmission,
            mileage: settings.mileage,
            paymentTerms: settings.paymentTerms,
            // Also comparison filter
            comparisonType: analysis.settings.comparisonFilter.key,
            comparisonValue: analysis.settings.comparisonFilter.values.value,
        };
    }

    get allowedLocationPerPos() {
        const { allowedPosCodeLocations } = this.carsSharedService.filters;
        if (!allowedPosCodeLocations) {
            return [];
        }
        const allowedLocationPerPos = new Map();
        Object.keys(allowedPosCodeLocations).forEach(pos => {
            Object.keys(allowedPosCodeLocations[pos]).forEach(location => {
                if (!allowedLocationPerPos.has(location)) {
                    allowedLocationPerPos.set(location, [pos]);
                } else {
                    allowedLocationPerPos.set(location, [...allowedLocationPerPos.get(location), pos]);
                }
            });
        });
        return allowedLocationPerPos;
    }

    get allowedVendorsPerCountry() {
        const { allowedVendorsPerCountry } = this.carsSharedService.filters;
        return allowedVendorsPerCountry;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    getCountryByPickUpLocationId(locationId: string) {
        const { countries } = this.carsSharedService.filters;
        if (!countries) {
            return null;
        }
        return Object.keys(countries).filter(country => countries[country].filter(location => location === locationId).length)[0];
    }

    getCountryByPOS(pos: string) {
        const { countryCodes } = this.carsSharedService.filters;
        if (!countryCodes) {
            return null;
        }
        return countryCodes.filter(countryCode => countryCode.code === pos)?.[0]?.name || '';
    }

    getAvailableDataSourceByCountry(country: string): string[] {
        const { availability, dataSources, countries, availabilityVans } = this.carsSharedService.filters;

        const { isVans } = this.storeState.settings;

        const availabilities = isVans ? availabilityVans : availability;

        if (!availabilities || !countries || !countries[country]) {
            return [];
        }
        const locations = countries[country];
        const currentLocations: Record<any, any>[] = [];
        locations.forEach(location => {
            const availableLocation = availabilities.find(availabilityLocation => availabilityLocation.id === location);
            if (availableLocation) {
                currentLocations.push(availableLocation);
            }
        });

        if (!currentLocations.length) {
            return [];
        }
        const availableDataSources: any = [];
        currentLocations.forEach(currentLocation => {
            availableDataSources.push(Object.keys(currentLocation.path).filter(item => (dataSources || []).includes(item)));
        });
        return Array.from(new Set(_.flatten(availableDataSources)));
    }

    get competitorsFromCompsetSettings() {
        if (!this.allowedVendorsPerCountry) {
            return {};
        }
        const competitorsFromSettings: Record<string, string[]> = {};
        Object.keys(this.allowedVendorsPerCountry).forEach(country => {
            Object.keys(this.allowedVendorsPerCountry[country]).forEach(dataSource => {
                if (!competitorsFromSettings[dataSource]) {
                    competitorsFromSettings[dataSource] = [];
                }
                competitorsFromSettings[dataSource].push(...this.allowedVendorsPerCountry[country][dataSource]);
            });
        });
        return competitorsFromSettings;
    }

    getCompetitorsFilterByDataSource(dataSource: string, pos: string): string[] {
        const { currentCompany } = this.userService;
        const { allowedBrands } = this.carsSharedService.filters;
        const allowedVendorsPerPosAndDataSource = this.getAllowedVendorsPerPosAndDataSource(dataSource, pos);
        let competitors = [];
        if (dataSource === 'Brand') {
            if (allowedBrands === null) {
                return [];
            }
            competitors = allowedBrands.filter((brand: string) => brand !== currentCompany);
        } else {
            const vendorsList = this.carsSharedService.vendorsList as { [s: string]: string[] };
            competitors = vendorsList[dataSource] || [] as any;
        }

        return allowedVendorsPerPosAndDataSource.length
            ? competitors.filter(item => allowedVendorsPerPosAndDataSource.includes(item))
                .filter(item => this.isProviderAvailableInCurrentPOS(item, pos))
            : competitors.filter(item => this.isProviderAvailableInCurrentPOS(item, pos));
    }

    getClustersKeys(skipAnyValue: boolean = false) {
        let sortedKeys = this.clusters && Object.keys(this.clusters || {}).length ? Object.keys(this.clusters).sort() : [];
        if (skipAnyValue) {
            sortedKeys = sortedKeys.filter(value => value !== COUNTRIES_ANY);
        }
        return sortedKeys.map(value => ({
            name: String(value),
            value: String(value),
        }));
    }
}
