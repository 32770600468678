import { Expose, Transform } from 'class-transformer';
import { IsString, IsDate, IsBoolean } from 'class-validator';
import { CarAlertType } from '../../modules/settings/config/alerts-notification.config';

export default class CarAlertModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id)
    @IsString()
    id!: string;

    @Expose()
    @Transform((_, plain) => plain.user_id)
    @IsString()
    userId!: number;

    @Expose()
    @Transform((_, plain) => plain.alert_type)
    @IsString()
    alertType!: CarAlertType;

    @Expose()
    @Transform((_, plain) => plain.message)
    @IsString()
    message!: string;

    @Expose()
    @Transform((_, plain) => plain.alert_name)
    @IsString()
    title!: string;

    @Expose()
    @Transform((_, plain) => plain.chain_id)
    @IsString()
    chainId!: string;

    @Expose()
    @Transform((_, plain) => new Date(plain.date || plain.created_at))
    @IsDate()
    date!: Date;

    @Expose()
    @Transform((_, plain) => !!plain.read)
    @IsDate()
    read!: Boolean;

    @Expose()
    @Transform((_, plain) => plain.ext_data)
    extData!: any;

    @Expose()
    @Transform((_, plain) => plain.insights_alert_id)
    @IsString()
    insightsAlertId!: string;

    @Expose()
    @Transform((_, plain) => new Date(plain.pick_up_date))
    @IsDate()
    pickUpDate!: Date;

    @Expose()
    @Transform((_, plain) => [CarAlertType.RATE_DIFF, CarAlertType.COMPSET_DIFF].includes(plain.alert_type) && plain.ext_data && plain.ext_data.link)
    @IsBoolean()
    hasLink!: boolean;

    @Expose()
    @Transform((_, plain) => new Date(plain.updated_at))
    @IsDate()
    updatedAt!: Date;
}
